@charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:    Admin Template
Version:    1.0.0
Created:    28-11-2023
Use:
-------------------------------------------------------------------*/

/*--- 1. Import Fonts ----*/

$font-primary: "Manrope";
@font-face {
  font-family: $font-primary;
  src: url(../Assets/Fonts/Manrope/Manrope-Bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: $font-primary;
  src: url(../Assets/Fonts/Manrope/Manrope-SemiBold.ttf);
  font-weight: 600;
}
@font-face {
  font-family: $font-primary;
  src: url(../Assets/Fonts/Manrope/Manrope-Medium.ttf);
  font-weight: 500;
}
@font-face {
  font-family: $font-primary;
  src: url(../Assets/Fonts/Manrope/Manrope-Regular.ttf);
  font-weight: 400;
}
@font-face {
  font-family: $font-primary;
  src: url(../Assets/Fonts/Manrope/Manrope-Light.ttf);
  font-weight: 300;
}

$font-secondary: "Catamaran";
@font-face {
  font-family: $font-secondary;
  src: url(../Assets/Fonts/Catamaran/Catamaran-Bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: $font-secondary;
  src: url(../Assets/Fonts/Catamaran/Catamaran-SemiBold.ttf);
  font-weight: 600;
}
@font-face {
  font-family: $font-secondary;
  src: url(../Assets/Fonts/Catamaran/Catamaran-Medium.ttf);
  font-weight: 500;
}
@font-face {
  font-family: $font-secondary;
  src: url(../Assets/Fonts/Catamaran/Catamaran-Regular.ttf);
  font-weight: 400;
}
@font-face {
  font-family: $font-secondary;
  src: url(../Assets/Fonts/Catamaran/Catamaran-Light.ttf);
  font-weight: 300;
}

$font-inter: "Inter";
@font-face {
  font-family: $font-inter;
  src: url(../Assets/Fonts/Inter/Inter-Thin.ttf);
  font-weight: 300;
}
@font-face {
  font-family: $font-inter;
  src: url(../Assets/Fonts/Inter/Inter-Regular.ttf);
  font-weight: 400;
}
@font-face {
  font-family: $font-inter;
  src: url(../Assets/Fonts/Inter/Inter-Medium.ttf);
  font-weight: 500;
}
@font-face {
  font-family: $font-inter;
  src: url(../Assets/Fonts/Inter/Inter-SemiBold.ttf);
  font-weight: 600;
}
@font-face {
  font-family: $font-inter;
  src: url(../Assets/Fonts/Inter/Inter-Bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: $font-inter;
  src: url(../Assets/Fonts/Inter/Inter-ExtraBold.ttf);
  font-weight: 800;
}

/* --- 2. Colors --- */
$primary_color: #1c359f;
$primary_dark: #282eeb;
$cornflower_blue: #6885FF;
$grey: #2d2d2d;
$header_bg: #1a173f;
$card_title: #5a596b;

$secondary_color: #495654;
$dark_green: #2e6a0f;
$body_bg: #f6f9ff;
$hover_color: #0b1fca;
$white: #ffffff;
$black: #000000;
$dark_grey: #010101;
$secondary_btn_hover: #edf3ff;
$secondary_hover_border: #0b1fca;
$light_grey: #6d6d6d;
$tab_color: #bebebe;
$card_bg: #f2f6f6;
$toast_success_border: #00d1b1;
$toast_success_bg: #d4ece8f5;
$toast_success_color: #089487;
$toast_fail_bg: #f9e5e5;
$input_boder: #d2d2d2;
$input_placeholder: #9ea3aa;
$error: #ff523b;
$accordion_bg: #f0f0f0;
$delete: #ef4a3c;
$badge_fail: #de2020;
$footer_bg: #f6f9ff;
$green: #16a377;
$orange: #ffb61d;
$red: #ff0000;
$off_white: #f3f3f3;
$light_green: rgba(22, 163, 119, 0.1);
$search_bg: #f3f3f3;
$notification_boder: #f0f0f0;
$secondary_grey: #4f4f4f;
$download_bg: #edf3ff;
$profile_bg: #edf3ff;
$status_bg: #0fd99c;
$tooltip_color: #eef2f5;
$table_row: #f1f7f2;
$accordion_body_bg: #dbdbdb;
$toast_color: #f1f1f1;
$warning_color: #ffc300;
$transaction_bg: #fafafa;
$chinese_black: #181614;
$soap : #D2D5EA;
$ghost_white : #F5F6FF;
$anti_flash_white: #F0F0F9;
$egyptian_blue: #1C359F;
$lavender: #E5E5FE;
$cyan_azure : #567DB7;
$lotion: #FCFCFD;
$cornsilk: #FFF3DE;
$ochre: #C7851E;
$snow: #FFF6F8;
$misty_rose: #FEE2E2;
$raisin_black: #212121;
$cosmic_latte : #FFFCE5;
$mustard_yellow : #DCAF06;
$nyanza_green : #ECFCE5;
$go_green : #0DBF66;
$lavender_blue_dark: #DFE4FE;

// Blur Colors
$alice_blue  : #F0F3FF;
$lavender_blue: #D1D6FF;

// Grey Colors
$light_gray: #CED4DA;
$roman_silver : #7F8591; 
$gainsboro : #ddd;
$chinese_white : #E4E1E5;
$cultured: #F4F6F9;
$slate_gray:#777E90;
$dark_silver : #6d6d6d;
$bright_gray: #E6E8EC;
$platinum_gray : #E6E7E6;
$sonic_silver: #757575;

$smoky_black : #0D0D0D05;
$dark_liver: #4F4F4F;
$onyx: #353945;

$goal_bg:#6DD73533;
$pale_cornflower_blue :#b0cbec;


// Exporting colors
:export  {
  roman_silver:$roman_silver;
  white: $white;
  primary_color:$primary_color;
  mustard_yellow : $mustard_yellow;
  ochre: $ochre;
  red:$red
}

/* --- 3. Font --- */
%font10 {
  font-size: 0.625rem;
}
%font11 {
  font-size: 0.688rem;
}
%font12 {
  font-size: 0.75rem;
}
%font14 {
  font-size: 0.875rem;
}
%font16 {
  font-size: 1rem;
}
%font18 {
  font-size: 1.125rem;
}
%font20 {
  font-size: 1.25rem;
}
%font24 {
  font-size: 1.5rem;
}
%font32 {
  font-size: 2rem;
}
%font36 {
  font-size: 2.25rem;
}
%font48 {
  font-size: 3rem;
}

/* --- 2. Global --- */
%heading {
  color: $dark_grey;
  font-weight: 600 !important;
  -webkit-font-smoothing: antialiased;
}
%h3 {
  @extend %font20;
}
%h4 {
  color: $dark_grey;
  @extend %font24;
  font-weight: 700;
  line-height: 120%;
  -webkit-font-smoothing: antialiased;
}
%h6 {
  color: $dark_grey;
  @extend %font14;
  line-height: 17px;
  font-weight: 600 !important;
}
.modal-dialog {
  // max-width: max-content;
}
.modal-content {
  padding: 15px;
  border-radius: 8px !important;
}
.modal-header {
  border-bottom: none !important;
}
.btn-close {
  opacity: 10 !important;
}
button:focus:not(:focus-visible) {
  outline: none !important;
}
.btn-close:focus {
  box-shadow: none !important;
}
.btn-close:hover {
  transform: scale(1.1);
}

/* ---3. Mixin styles ---*/

// primary button
@mixin hover_animation() {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  transition: all 0.35s;
  z-index: 1;
  opacity: 0;
}

/* ---4. variable ---*/
