/*
 *   File : acccordion.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : FAQ Accordion style
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";

.e_investment_without_goals {
  .e_accordion_sec {
    border: 1px solid $accordion_body_bg;
    border-radius: 8px;
    width: 100%;
    padding: 16px 24px;
    margin-bottom: 20px;
  }

  .e_accordion_item {
    border: none !important;
    .e_accordion_header {
      background: $white;
    }
  }
  .e_accordion_title {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #2d2d2d;
  }
  .e_accordion_sub_title {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #2d2d2d;
  }

  .e_accordion_wrapper {
    background: $white;
    border-radius: 8px;

    margin: 2px;

    .e_accordion_content {
      font-weight: 400;
      @extend %font14;
      line-height: 150%;
      color: $dark_grey;
      h6 {
        @extend %font14;
        font-weight: 600;
        padding-top: 15px;
      }
    }

    .e_accordion_body_wrapper {
      background: #f9f5ff;
      border-radius: 8px;
      padding: 16px;
      h6 {
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: #6d6d6d;
      }
      p {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #2d2d2d;
      }
    }
    .e_accordion_body_wrapper_value {
      background: #fff5f7;
    }
    .e_accordion_body_wrapper_invested {
      background: #f5fdff;
    }
    .e_investment_wrapper {
      background: #f5f6ff;
      border-radius: 8px;
      padding: 16px 32px;
      h6 {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #6d6d6d;
      }
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #2d2d2d;
      }
    }
  }
}

.e_accordion_body {
  padding: 0 0 !important;
}
