/*
 *   File : acccordion.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : FAQ Accordion style
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";

.e_faq_accordion {
	.e_accordion_sec {
		border: 1px solid $accordion_body_bg;
		border-radius: 8px;
		width: 100%;
		padding: 0px 10px 14px;
		margin-bottom: 20px;
	}
	.e_add_sec {
		border: 1px solid $accordion_body_bg;
		border-radius: 8px;
		width: 100%;
		padding: 7px 10px 14px;
		margin-bottom: 20px;

		h6 {
			color: $primary_color !important;
			@extend %font14;
			line-height: 24px;
			font-weight: 700 !important;
			padding: 12px 10px 0px;
			width: 100%;
			cursor: pointer;
			&:hover {
				translate: scale(1.5);
			}
		}
	}
	.e_accordion_button {
		border: none !important;
		background-color: $white;
		text-align: left;
		width: 100%;
		position: relative;
		border-radius: 0;
		outline: none;
		// &[aria-expanded="false"] {
		.e_accordion_icon {
			position: absolute;
			top: 5% !important;
			right: 0px;
			background-image: url(../../Assets/Images/DataFormating/add.svg);
			background-repeat: no-repeat;
			padding: 13px;
			transition: 0.5s ease-in-out;
		}
		// }
		// &[aria-expanded="true"] {
		.e_accordion_icon_minus {
			position: absolute;
			top: 5% !important;
			right: 0px;
			background-image: url(../../Assets/Images/DataFormating/minus.svg);
			background-repeat: no-repeat;
			padding: 13px;
			transition: 0.5s ease-in-out;
		}
		// }
	}
	.e_accordion_item {
		border: none !important;
		.e_accordion_header {
			background: $white;
		}
	}
	.e_accordion_title {
		font-weight: 500;
		@extend %font14;
		line-height: 24px;
		color: $dark_grey;
		&:hover {
			cursor: pointer;
			text-decoration: underline;
		}
	}
	.e_accordion_sub_title {
		font-weight: 700;
	    font-size: 0.875rem !important;
		line-height: 20px;
		padding: 0px 16px;
		color: $dark_grey;
		&:hover {
			cursor: pointer;
			text-decoration: none;
		}
	}
	.e_accordion_wrapper {
		background: $accordion_bg;
		border-radius: 8px;
		padding: 10px;
		margin: 2px;
		.e_accordion_content_img {
			width: 4%;
		}
		.e_accordion_content {
			font-weight: 400;
			@extend %font14;
			line-height: 150%;
			color: $dark_grey;
			h6 {
				@extend %font14;
				font-weight: 600;
				padding-top: 15px;
			}
		}
	}
	.e_change {
		height: 40px;
		text-align: center;
		border: 1px solid $primary_color;
		padding: 8px 12px;
		border-radius: 8px;
		font-weight: 700;
		&:hover {
			opacity: 0.7;
		}
	}
	.e_delete {
		height: 40px;
		text-align: center;
		border: 1px solid $badge_fail;
		padding: 8px 12px;
		border-radius: 8px;
		font-weight: 700;
		color: $badge_fail;
		font-weight: 700;
		&:hover {
			opacity: 0.7;
		}
	}
	.e_edit {
		height: 40px;
		text-align: center;
		border: 1px solid $primary_color;
		padding: 8px 12px;
		border-radius: 8px;
		font-weight: 700;

		&:hover {
			opacity: 0.7;
		}
	}
}
.e_allocation_accordion {
	.e_accordion_sec {
		border: 1px solid $accordion_body_bg;
		border-radius: 8px;
		width: 100%;
		padding: 0px 10px 14px;
		margin-bottom: 20px;
	}
	.e_accordion_body_tab {
		position: relative;
		&::before {
			content: "";
			position: absolute;
			height: 0.2px;
			width: 100%;
			bottom: 1px;
			background-color: #dbdbdb;
		}
	}
	.e_add_sec {
		border: 1px solid $accordion_body_bg;
		border-radius: 8px;
		width: 100%;
		padding: 7px 10px 14px;
		margin-bottom: 20px;

		h6 {
			color: $primary_color !important;
			@extend %font14;
			line-height: 24px;
			font-weight: 700 !important;
			padding: 12px 10px 0px;
			width: 100%;
			cursor: pointer;
			&:hover {
				translate: scale(1.5);
			}
		}
	}

	.e_accordion_button {
		border: none !important;
		background-color: $white;
		text-align: left;
		width: 100%;
		position: relative;
		border-radius: 0;
		outline: none;
		// &[aria-expanded="false"] {
		.e_accordion_icon {
			position: absolute;
			top: 35%;
			right: 0px;
			background-image: url(../../Assets/Images/DataFormating/add.svg);
			background-repeat: no-repeat;
			padding: 13px;
			transition: 0.5s ease-in-out;
		}
		// }
		// &[aria-expanded="true"] {
		.e_accordion_icon_minus {
			position: absolute;
			top: 35%;
			right: 0px;
			background-image: url(../../Assets/Images/DataFormating/minus.svg);
			background-repeat: no-repeat;
			padding: 13px;
			transition: 0.5s ease-in-out;
		}
		// }
	}
	.e_accordion_item {
		border: none !important;
		.e_accordion_header {
			background: $white;
		}
	}
	.e_accordion_title {
		font-weight: 500;
		@extend %font14;
		line-height: 24px;
		color: $dark_grey;
		&:hover {
			cursor: pointer;
			text-decoration: underline;
		}
	}
	.e_accordion_sub_title {
		font-weight: 700;
		font-size: 0.875rem !important;
		line-height: 20px;
		padding: 0px 16px;
		color: $dark_grey;
		&:hover {
			cursor: pointer;
			text-decoration: none;
		}
	}
	.e_accordion_wrapper {
		background: $white;
		border-radius: 8px;
		padding: 10px;
		margin: 2px;

		.e_accordion_content_img {
			width: 4%;
		}
		.e_accordion_content {
			font-weight: 400;
			@extend %font14;
			line-height: 150%;
			color: $dark_grey;
			h6 {
				@extend %font14;
				font-weight: 600;
				padding-top: 15px;
			}
		}
	}
	.e_save {
		height: 40px;
		text-align: center;
		border: 1px solid $primary_color;
		padding: 8px 18px 8px 12px;
		border-radius: 8px;
		font-weight: 600;
		&:hover {
			opacity: 0.7;
		}
	}
	.e_cancel {
		height: 40px;
		text-align: center;
		border: 1px solid $tab_color;
		padding: 8px 15px 8px 12px;
		border-radius: 8px;
		font-weight: 700;
		color: $light_grey;
		font-weight: 600;
		&:hover {
			opacity: 0.7;
		}
	}
	.e_edit {
		height: 40px;
		text-align: center;
		border: 1px solid $primary_color;
		padding: 8px 12px;
		border-radius: 8px;
		font-weight: 700;

		&:hover {
			opacity: 0.7;
		}
	}
	.e_tab {
		@extend %font14;
		font-weight: 600;
		line-height: 120%;
		color: $tab_color;
		transition: ease 0.3s;
		padding-bottom: 5px;
		position: relative;
		&:before {
			content: "";
			position: absolute;
			width: 0;
			height: 3px;
			background-color: $primary_color;
			bottom: 0;
			transition: all 0.35s;
		}
		&:hover {
			&:before {
				width: 100%;
			}
			cursor: pointer;
			opacity: 1;
		}
	}
	.e_active_tab {
		@extend .e_tab;
		transition: ease 0.3s;
		opacity: 1;
		color: $light_grey;
		font-weight: 600;
		&:after {
			content: "";
			position: absolute;
			width: 100%;
			height: 2px;
			background-color: $primary_color;
			bottom: 0px;
			left: 0;
		}
	}
}
.e_returns_accordion {
	.e_accordion_sec {
		border: 1px solid $accordion_body_bg;
		border-radius: 8px;
		width: 100%;
		padding: 0px 10px 14px;
		margin-bottom: 20px;
	}
	.e_accordion_body_tab {
		position: relative;
		&::before {
			content: "";
			position: absolute;
			height: 0.2px;
			width: 100%;
			bottom: 1px;
			background-color: #dbdbdb;
		}
		.e_tab {
			@extend %font14;
			font-weight: 600;
			line-height: 120%;
			color: $tab_color;
			transition: ease 0.3s;
			padding-bottom: 5px;
			position: relative;
			&:before {
				content: "";
				position: absolute;
				width: 0;
				height: 3px;
				background-color: $primary_color;
				bottom: 0;
				transition: all 0.35s;
			}
			&:hover {
				&:before {
					width: 100%;
				}
				cursor: pointer;
				opacity: 1;
			}
		}
		.e_active_tab {
			@extend .e_tab;
			transition: ease 0.3s;
			opacity: 1;
			color: $light_grey;
			font-weight: 600;
			&:after {
				content: "";
				position: absolute;
				width: 100%;
				height: 2px;
				background-color: $primary_color;
				bottom: 0px;
				left: 0;
			}
		}
	}
	.e_add_sec {
		border: 1px solid $accordion_body_bg;
		border-radius: 8px;
		width: 100%;
		padding: 7px 10px 14px;
		margin-bottom: 20px;

		h6 {
			color: $primary_color !important;
			@extend %font14;
			line-height: 24px;
			font-weight: 700 !important;
			padding: 12px 10px 0px;
			width: 100%;
			cursor: pointer;
			&:hover {
				translate: scale(1.5);
			}
		}
	}

	.e_accordion_button {
		border: none !important;
		background-color: $white;
		text-align: left;
		width: 100%;
		position: relative;
		border-radius: 0;
		outline: none;
		// &[aria-expanded="false"] {
		.e_accordion_icon {
			position: absolute;
			top: 5%;
			right: 0px;
			background-image: url(../../Assets/Images/DataFormating/add.svg);
			background-repeat: no-repeat;
			padding: 13px;
			transition: 0.5s ease-in-out;
		}
		// }
		// &[aria-expanded="true"] {
		.e_accordion_icon_minus {
			position: absolute;
			top: 5%;
			right: 0px;
			background-image: url(../../Assets/Images/DataFormating/minus.svg);
			background-repeat: no-repeat;
			padding: 13px;
			transition: 0.5s ease-in-out;
		}
		// }
	}
	.e_accordion_item {
		border: none !important;
		.e_accordion_header {
			background: $white;
		}
	}
	.e_accordion_title {
		font-weight: 500;
		@extend %font14;
		line-height: 24px;
		color: $dark_grey;
		&:hover {
			cursor: pointer;
			text-decoration: underline;
		}
	}
	.e_accordion_sub_title {
		font-weight: 700;
		font-size: 0.875rem !important;
		line-height: 20px;
		padding: 0px 16px;
		color: $dark_grey;
		&:hover {
			cursor: pointer;
			text-decoration: none;
		}
	}
	.e_accordion_wrapper {
		background: $white;
		border-radius: 8px;
		padding: 10px;
		margin: 2px;

		.e_accordion_content_img {
			width: 4%;
		}
		.e_accordion_content {
			font-weight: 400;
			@extend %font14;
			line-height: 150%;
			color: $dark_grey;
			h6 {
				@extend %font14;
				font-weight: 600;
				padding-top: 15px;
			}
		}
	}
	.e_save {
		height: 40px;
		text-align: center;
		border: 1px solid $primary_color;
		padding: 8px 18px 8px 12px;
		border-radius: 8px;
		font-weight: 600;
		&:hover {
			opacity: 0.7;
		}
	}
	.e_delete {
		color: $red;
		font-weight: 600;
		&:hover {
			opacity: 0.7;
		}
	}
}
.e_accordion_body {
	padding: 0 0 !important;
}

.e_quiz_accordion {
	.e_accordion_sec {
		border: 1px solid $accordion_body_bg;
		border-radius: 8px;
		width: 100%;
		padding: 4px 10px 14px;
		margin-bottom: 20px;
	}
	.e_add_sec {
		border: 1px solid $accordion_body_bg;
		border-radius: 8px;
		width: 100%;
		padding: 7px 10px 14px;
		margin-bottom: 20px;

		h6 {
			color: $primary_color !important;
			@extend %font14;
			line-height: 24px;
			font-weight: 700 !important;
			padding: 12px 10px 0px;
			width: 100%;
			cursor: pointer;
			&:hover {
				translate: scale(1.5);
			}
		}
	}
	.e_accordion_button {
		border: none !important;
		background-color: $white;
		text-align: left;
		width: 100%;
		position: relative;
		border-radius: 0;
		outline: none;
		// &[aria-expanded="false"] {
		.e_accordion_icon {
			position: absolute;
			top: 32%;
			right: 0px;
			background-image: url(../../Assets/Images/DataFormating/add.svg);
			background-repeat: no-repeat;
			padding: 13px;
			transition: 0.5s ease-in-out;
		}
		// }
		// &[aria-expanded="true"] {
		.e_accordion_icon_minus {
			position: absolute;
			top: 35%;
			right: 0px;
			background-image: url(../../Assets/Images/DataFormating/minus.svg);
			background-repeat: no-repeat;
			padding: 13px;
			transition: 0.5s ease-in-out;
		}
		// }
	}
	.e_accordion_item {
		border: none !important;
		.e_accordion_header {
			background: $white;
		}
	}
	.e_accordion_title {
		font-weight: 500;
		@extend %font14;
		line-height: 20px;
		color: $dark_grey;
		&:hover {
			cursor: pointer;
			text-decoration: underline;
		}
	}
	.e_accordion_sub_title {
		font-weight: 700;
	font-size: 0.875rem !important;
		line-height: 20px;
		padding: 0px 16px;
		color: $dark_grey;
		&:hover {
			cursor: pointer;
			text-decoration: none;
		}
	}
	.e_accordion_wrapper {
		background: $accordion_bg;
		border-radius: 8px;
		padding: 14px 6px;
		margin: 2px;
		display: inherit;
		h6 {
			@extend %font12;
			font-weight: 700 !important;
		}
		li {
			@extend %font14;
			line-height: 34px;
			color: $dark_grey;
			opacity: 0.87;
		}
		.e_accordion_content_img {
			width: 4%;
		}
		.e_accordion_content {
			font-weight: 400;
			@extend %font14;
			line-height: 150%;
			color: $dark_grey;
			li::marker {
				font-size: 8px;
				font-weight: 300;
				color: $dark_grey;
			}
		}
		.e_accordion_content_config {
			font-weight: 400;
			@extend %font14;
			line-height: 150%;
			list-style: none;
			padding-left: 0px !important;
			color: $dark_grey;
			li::marker {
				font-size: 8px;
				font-weight: 300;
				color: $dark_grey;
			}
			span {
				font-weight: 600;
				color: $black !important;
				opacity: 1;
			}
		}
	}
	.e_delete {
		height: 40px;
		text-align: center;
		border: 1px solid $badge_fail;
		padding: 8px 12px;
		border-radius: 8px;
		font-weight: 700;
		color: $badge_fail;
		font-weight: 700;
		&:hover {
			opacity: 0.7;
		}
	}
	.e_edit {
		height: 40px;
		text-align: center;
		border: 1px solid $primary_color;
		padding: 8px 12px;
		border-radius: 8px;
		font-weight: 700;

		&:hover {
			opacity: 0.7;
		}
	}
}
.e_accordion_body {
	padding: 0 0 !important;
}
