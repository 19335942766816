/*
 *   File : primary.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Primary Button Style
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";

.e_p_btn {
  border: none;
  outline: none;
  background: $primary_color;
  padding: 12px 24px;
  color: $white;
  font-weight: 600;
  border-radius: 8px;
  @extend %font18;
  line-height: 120%;
  position: relative;
  z-index: 0;
  font-size: 14px !important;
  &:after {
    @include hover_animation();
    color: $white !important;
    background: $primary_dark;
    border-radius: 8px;
  }
  &:hover:after {
    opacity: 1;
    width: 100%;
    box-shadow: none !important;
    border-radius: 8px;
  }
  span,
  img {
    z-index: 999;
    position: relative;
    @extend %font14;
  }
}
.e_disabled {
  opacity: 0.5;
  pointer-events: none;
  font-weight: 600;
}
