/*
 *   File : dataTable.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : dataTable.module.scss
 *   Integrations :
 *   Version : v1.0
 */

.rdt_TableCol {
  color: $light_grey !important;
  @extend %font14;
  font-weight: 600 !important;
  line-height: 19px !important;
  -webkit-font-smoothing: antialiased;
  font-weight: 600 !important;
  &:hover {
    color: rgba(0, 0, 0, 0.54);
  }
}
.hkcGBu {
  font-weight: unset !important;
}
.rdt_TableHeader {
  display: none !important;
}
.hmvnLu,
.boClDs {
  background-color: rgb(227, 242, 253) !important;
  border-bottom-style: solid !important;
  border-bottom-width: 1px !important;
  border-bottom-color: #ffffff !important;
}
.sc-hHEiqL::-webkit-scrollbar {
  display: none !important;
}
.rdt_TableHeadRow {
  border-bottom-color: transparent !important;
  background: rgba(255, 255, 255, 0.67) !important;
}
.rdt_TableCell,
.fvAeSn a {
  color: $dark_grey;
  font-weight: 400 !important;
  @extend %font14;
  line-height: 18px !important;
  -webkit-font-smoothing: antialiased;
  text-decoration: none;
}
.rdt_TableBody {
  cursor: pointer;
  padding-bottom: 45px;
  .rdt_TableRow:nth-of-type(odd) {
    background: rgba(13, 13, 13, 0.02);
    // padding:10px 0px;
    border-bottom: none;
  }
  .rdt_TableRow:nth-of-type(odd) {
    // padding:10px 0;
  }
}

.sc-dlVxhl,
.sc-dmctIk {
  overflow-x: auto;
  &::-webkit-scrollbar {
    // border-radius: 10px;
    height: 0.25rem;
    width: 0.375rem;
    width: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background: #c4dcf3 !important;
    padding: 10px;
    border-radius: 10px;
    // border-radius: 10px;
  }
}
.rdt_TableRow {
  transition: ease 0.3s;
  &:hover {
    background-color: $table_row !important;
  }
}
.dwPlXY:not(:last-of-type),
.cohCfu:not(:last-of-type) {
  border-bottom-color: transparent !important;
}
.dwPlXY:not(:last-of-type),
.cohCfu:not(:last-of-type) {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  // padding: 10px 0px;
  border-bottom-color: transparent;
}
.rdt_Table {
  overflow-y: scroll !important;
}
.rdt_Table::-webkit-scrollbar {
  display: none;
}
.bWTWQu {
  color: $dark_grey;
  font-weight: 400 !important;
  @extend %font14;
  line-height: 17px;
}
.kNbUsB {
  background: $off_white !important;
  border-radius: 4px;
}
.gPLhoV {
  margin-right: 10px !important;
  position: relative;
  border-radius: 8px !important;
  background-color: $off_white !important;
}
.gqExIP {
  display: none;
}
.sc-kDTinF,
.jbTKDn {
  background-image: url(../../Assets/Images/DataFormating/table_sort.png);
  background-repeat: no-repeat;
  background-position: 28% 50%;
  background-size: 12px;
}
.sc-kDTinF {
  color: transparent !important;
  right: 12%;
  // position: absolute;
}
// .sc-iJKOTD{
//   display: none!important;
// }
.sc-kDTinF,
.hLGwHm {
  background-image: url(../../Assets/Images/DataFormating/table_sort_up.png);
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-size: 12px;
}
.e-time {
  @extend %font12;
}
.e-status-badge {
  border-radius: 20px;
  font-weight: 600;
  @extend %font12;
  line-height: 14px;
  text-align: center;
  background: $status_bg;
  border-radius: 100px;
  padding: 3px 15px 4px;
  color: $white;
  text-transform: capitalize;
  width: 80px;
  padding: 4px 0px;
}
.e-status-badge.e-status-badge-fail {
  background: $badge_fail;
  color: $white;
  padding: 4px 0px;
}
.e-status-badge.e-status-badge-pending {
  background: $orange;
  color: $white;
  padding: 4px 0px;
}
.e-status-badge.e-status-badge-partially {
  background: $orange;
  color: $white;
  padding: 4px 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.e-icon {
  background-image: url(../../Assets/Images/DataFormating/info-icon.svg);
  width: 15px;
  height: 15px;
  background-size: 15px;
  background-repeat: no-repeat;
  display: inline-block;
  cursor: pointer;
  position: relative;
  right: -10px;
  top: 4px;
  z-index: 0;
}
.e-more {
  background-image: url(../../Assets/Images/DataFormating/more.svg);
  width: 15px;
  height: 15px;
  background-size: 15px;
  background-repeat: no-repeat;
  display: inline-block;
  top: 0px;
  cursor: pointer;
  margin: 24px 55px 10px 10px;
  // margin-right: 55px;
  // margin-top: 18px;
  z-index: 0;
}
.e-card {
  width: 160px;
  background: $white;
  box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  position: absolute;
  top: 33px;
  right: 34px;
  z-index: 1;
  padding: 10px 15px;
  line-height: 25px;
  span {
    @extend %font14;
    color: $dark_grey;
    font-weight: 400;
    line-height: 17px;
    &:hover {
      opacity: 0.7;
    }
    img {
      position: relative;
      top: -2px;
    }
  }
}
.e-card-transaction {
  width: 148px;
  background: $white;
  box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  position: absolute;
  top: 65%;
  right: 10%;
  z-index: 1;
  padding: 13px 15px;
  line-height: 25px;
  height: 50px;
  span {
    margin-bottom: 12px;
    @extend %font14;
    &:hover {
      opacity: 0.7;
    }
    img {
      position: relative;
      top: -2px;
    }
  }
}
.e-card-analytics {
  width: 220px;
  background: #ffffff;
  box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  position: absolute;
  top: 33px;
  right: 34px;
  z-index: 1;
  padding: 13px 15px;
  line-height: 200%;
  // height: 120px;
  span {
    margin-bottom: 12px;
    @extend %font14;
    &:hover {
      opacity: 0.7;
    }
    img {
      position: relative;
      top: -2px;
    }
  }
}
.sc-hKwDye input[type="checkbox"] {
  height: 16px !important;
  width: 16px !important;
}
.lnndaO {
  font-weight: 600 !important;
}
.hLGqlg,
.sc-bdvvtL {
  position: relative;
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  // height: 120vh!important;
  max-width: 100%;
  color: rgba(0, 0, 0, 0.87);
  background-color: #ffffff;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 210vh !important;
}

.e-rank {
  width: 24px;
  height: 24px;
  color: #ffffff;
  padding: 4px;
  border-radius: 100px;
  display: inline-block;
  text-align: center;
}
.e-rank-1 {
  background: #ffca10;
}
.e-rank-2 {
  background: #d1d1d1;
}
.e-rank-3 {
  background: #e29b4b;
}
.e-leaderboard-rank {
  padding: 4px;
}
// .sc-dlVxhl {
//   overflow-x: auto;
//   &::-webkit-scrollbar {
//     // border-radius: 10px;
//      height: 0.25rem;
//     width: 0px;
//   }
//   &::-webkit-scrollbar-thumb {
//     background: #c4dcf3 !important;
//     padding: 10px;
//     border-radius: 10px;
//   }
// }

.sc-hHEiqL::-webkit-scrollbar {
  display: none !important;
}
