/*
 *   File : confirmation.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : FAQ Accordion style
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";
.e_confirmation {
  h6 {
    @extend %h6;
  }
  .e_search_fund {
    color: #9d9d9d !important;
  }
  p {
    @extend %font16;
    font-weight: 500;
    line-height: 130%;
    color: #2d2d2d;
    padding-top: 36px;
  }
  ::-webkit-scrollbar {
    border-radius: 10px;
    height: 0.25rem;
    width: 0.375rem;
  }
  .e_selected_fund {
    background: #ffffff;
    box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    padding: 22px 15px;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #2d2d2d;
    margin-bottom: 24px;
  }
  ::-webkit-scrollbar-thumb {
    background: #c4dcf3 !important;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  .e_search_funds_txt {
    font-weight: 600;
    @extend %font14;
    line-height: 130%;
    color: $light_grey;
    padding-top: 8px;
  }

  .e_delete {
    border: 1px solid #ff0000 !important;
    border-radius: 8px;
    padding: 8px 24px;
    span {
      color: #ff0000;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
    }
  }
  .e_cancel {
    border: 1px solid $primary_color !important;
    padding: 8px 42px;
    border-radius: 8px;
    span {
      color: $primary_color;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
    }
  }
}
