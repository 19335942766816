/*
 *   File : inneHeader.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Inner Header Style
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";

.e_inner_header_wrap {
  background-color: $header_bg;
  height: 250px;
  position: absolute;
  width: 100%;
  .e_notification {
    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
  .e_header_more {
    &:hover {
      cursor: pointer;
    }
  }
  .e_menu {
    position: relative;
    z-index: 999;
    &:hover {
      cursor: pointer;
    }
  }
  .e_drop_down_wrap {
    &:hover {
      cursor: pointer;
      .e_header_more {
        opacity: 0.7;
      }
    }
  }
  .e_dropdown {
    width: 182px;
    height: 100px;
    background: $white;
    // box-shadow: 8px 16px 56px rgb(0 0 0 / 12%);
    border-radius: 8px;
    z-index: 1;
    padding: 20px 15px;
    position: absolute;
    right: 2%;
    top: 23%;
    text-align: left;
    z-index: 2;
    span {
      font-size: 13px;
      display: block;
      margin-bottom: 18px;
      color: $dark_grey;
      font-weight: 400;
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
      img {
        position: relative;
        top: -2px;
      }
    }
    .e_logout {
      color: $delete;
    }
  }
  h3 {
    color: $white;
    font-weight: 600;
    @extend %font24;
    line-height: 31.2px;
    font-family: $font-primary;
    @extend %font24;
  }
}
