/*
 *   File : iconStyleToolTip.module.scss
 *   Author : https://evoqueinnovativelab.com
 *   Description : Tooltip style
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 28-11-2023
 */

@import "./../config.scss";

.e_icon_tooltip {
  background: $white;
  box-shadow: 2px 2px 48px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  padding: 25px 25px 0px 30px;
  position: absolute;
  z-index: 1;
  max-width: 346px;
  height: auto;
  right: 45%;
  span {
    font-weight: 500 !important;
    color: $dark_grey !important;
    font-size: 12px !important;
    line-height: 24px;
    letter-spacing: -0.02em;
    font-family: $font-primary;
    &:hover {
      // opacity: 0.8;
      cursor: pointer;
    }
  }
  a {
    display: block;
    color: $primary_color !important;
    font-size: 12px !important;
    text-decoration: underline !important;
    padding-top: 30px;
    line-height: 24px;
  }
}
