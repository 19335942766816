/*-- Import supporting css*/
@import "./config.scss";
@import "./Components/select-box.module.scss";
@import "./Components/data-table.module.scss";
@import "./Components/pie.module.scss";
@import "./Components/bar.module.scss";
@import "./Components/area.module.scss";
@import "./Components/stacked.module.scss";
@import "./Components/date-range-picker.module.scss";
@import "./Components/risk.module.scss";
@import "./Components/fund-class.module.scss";
@import "./Components/radio.module.scss";
@import "./Components/accordion.module.scss";
// @import "./Components/calendar.module.scss";


/*---------- Body ---------- */
body {
	font-family: $font-primary;
	background-color: $body_bg;
	-webkit-font-smoothing: antialiased;
	overflow-y: scroll;

	&::-webkit-scrollbar {
		// border-radius: 10px;
		width: 0.25rem;
		height: 0.25rem;
	}

	&::-webkit-scrollbar-thumb {
		background: $pale_cornflower_blue !important;
		padding: 0.375rem;
		width: 0.25rem;
		height: 0.25rem;
		// border-radius: 10px;
		// border-radius: 10px;
	}
}

label {
	@extend %font12;
	font-weight: 400;
}

a {
	text-decoration: none;
}

/*------- App layout --------*/
.e-content-wrap {
	min-height: 100vh;
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
}

.e-sidebar-wrap {
	transition: ease-in-out 0.5s;
	position: relative;
	height: 100vh;
	width: 307px;
}

.e-sidebar-collapsed {
	transition: ease-in-out 0.5s;
	width: 50px;
}

.e-content-body {
	// margin-top: 11.5%;
	margin-top: 5rem;
}

.border-bottom {
	border-bottom: solid 0.5px $light_grey;
}

.e-w-1600 {
	min-width: 1600px;
}

.e-w-800 {
	min-width: 600px;
}

/*----auth--------*/
.e-auth-wrapper {
	background-color: $white;

	.e-form-wrap {
		background: $white;
		box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.12);
		border-radius: 8px;
		padding: 25px;

		h4 {
			@extend %h4;
			line-height: 33px;
			font-family: $font-primary;
			color: $black;
			font-weight: 600;
		}

		p {
			font-weight: 400;
			@extend %font14;

			span {
				font-weight: 600;
			}
		}
	}

	.e-disabled {
		opacity: 0.5;
		pointer-events: none;
		font-weight: 700;
		@extend %font14;
	}
}

.e-auth-footer {
	margin-top: 100px;
}

.e-disabled {
	opacity: 0.5;
	pointer-events: none;
	font-weight: 700;
	@extend %font14;
}

/*------ Dashboard ------ */
.e-card-setcion-title,
.e-section-title {
	@extend %font14;
	line-height: 130%;
	letter-spacing: 0.007em;
	color: $grey;
}

.e-card-setcion-title {
	font-weight: 600;
}

.e-section-title {
	font-weight: 600;
}

.e-card-title {
	@extend %h6;
	font-weight: 600 !important;
	color: $roman_silver !important;
	@extend %font14;
}

.e-btn-active-wrapper {
	height: 39px;
	background: $primary_color;
	border-radius: 8px;
	padding: 8px 10px;
	margin-right: 12px;
	text-align: center;
	cursor: pointer;

	.e-btn-active {
		font-weight: 500;
		@extend %font14;
		line-height: 24px;
		color: $white;
	}
}

.e-equity {
	font-size: 14px;
	line-height: 21px;
	color: #2d2d2d;

	h6 {
		font-weight: 600 !important;
	}

	span {
		font-weight: 700;
	}
}

.e-btn-wrapper {
	height: 39px;
	background: $white;
	border-radius: 4px;
	padding: 6px 10px;
	margin-right: 12px;
	text-align: center;
	border: 1px solid #dbdbdb;
	border-radius: 8px;
	cursor: pointer;
	// filter: drop-shadow(1px 2px 3px rgba(0, 0, 0, 0.16));

	&:hover {
		transform: scale(1.02);
	}

	.e-btn-active {
		font-weight: 500;
		@extend %font14;
		line-height: 24px;
		color: $light_grey;
	}
}

.e-dashboard-card {
	border-radius: 16px;
	box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.04);
	background: $white;
	padding: 12px;
	// height: 100%;

	.e-dashboard-card-alloc {
		border-radius: 8px;
		box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.04);
		background: $white;
		padding: 12px;

		span {
			@extend %font12;
			color: $light_grey;
			font-weight: 600;
		}
	}

	.e-btn-active-wrapper {
		width: 48px;
		height: 48px;
		background: $primary_color;
		border-radius: 4px;
		padding: 8px 10px;
		margin-right: 12px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		.e-btn-active {
			font-weight: 500;
			@extend %font14;
			line-height: 24px;
			color: $white;
		}
	}

	.e-btn-wrapper {
		width: 48px;
		height: 48px;
		background: $white;
		border-radius: 4px;
		padding: 8px 10px;
		margin-right: 12px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		filter: drop-shadow(1px 2px 3px rgba(0, 0, 0, 0.16));

		&:hover {
			transform: scale(1.02);
		}

		.e-btn-active {
			font-weight: 500;
			@extend %font14;
			line-height: 24px;
			color: $light_grey;
		}
	}

	.css-26l3qy-menu,
	.css-4ljt47-MenuList {
		@extend %font12;
	}

	.e-radial {
		.apexcharts-canvas {
			left: -129px;
			top: -20px;
		}
	}

	.e-s-btn {

		// width: 86px;
		.secondary_e_s_btn__16DLu {
			padding: 10px;
		}
	}

	.e-piechart-label {
		top: 20px;
		right: 15%;

		h2 {
			color: $dark_grey;
			font-weight: 600;
			@extend %font24;
			line-height: 29px;
			margin-block-end: 6px;

			span {
				@extend %font18;
			}
		}

		p {
			color: $dark_grey;
			font-weight: 500;
			@extend %font14;
			line-height: 17px;
		}
	}
}

.no-scrollbar::-webkit-scrollbar {
    display: none !important;
}

.e-bar-scroll {
	position: relative;
	overflow-x: scroll;
	width: 100%;
}

.e-bar-scroll::-webkit-scrollbar-button {
	display: none;
}

.e-bar-scroll::-webkit-scrollbar {
	width: 0.375rem;
	 height: 0.25rem;
	padding: 20px;
	border-radius: 16px;
}

.e-bar-scroll::-webkit-scrollbar-track {
	background-color: #efefef;
	border-radius: 16px;
	margin: 20px;
	border-bottom: 3px solid white;
}

.e-bar-scroll::-webkit-scrollbar-thumb {
	background-color: #c0c0c0;
	border-radius: 16px;
	border: 0px solid #fff;
	border-bottom: 3px solid white;
}

.e-round-chart-container {
	height: 420px;
}

.e-btn-active-wrapper {
	width: 45px;
	height: 39px;
	background: $primary_color;
	border-radius: 4px;
	padding: 8px 10px;
	margin-right: 12px;
	text-align: center;
	cursor: pointer;

	.e-btn-active {
		font-weight: 500;
		@extend %font14;
		line-height: 24px;
		color: $white;
	}
}

.e-btn-wrapper {
	width: 45px;
	height: 39px;
	background: $white;
	border-radius: 4px;
	padding: 8px 10px;
	margin-right: 12px;
	text-align: center;
	cursor: pointer;
	filter: drop-shadow(1px 2px 3px rgba(0, 0, 0, 0.16));

	&:hover {
		transform: scale(1.02);
	}

	.e-btn-active {
		font-weight: 500;
		@extend %font14;
		line-height: 24px;
		color: $light_grey;
	}
}

.e-dashboard-row-2 {
	height: 515px;
	overflow-x: scroll;

	&::-webkit-scrollbar {
		// border-radius: 10px;
	 height: 0.25rem;
	}

	&::-webkit-scrollbar-thumb {
		background: #b0cbec !important;
		padding: 10px;
		// border-radius: 10px;
		// border-radius: 10px;
	}
}

/*------ App notification ------*/
.e-notification .tab_e_active_tab__3jjiq {
	background-color: transparent !important;
	color: $black;
	border-bottom: 3px solid $primary_color;
	border-radius: 0px;
	border-top: none;
	border-left: none;
	border-right: none;
	padding: 6px 0px;
	font-weight: 600;
	@extend %font14;
	line-height: 17px;
}

.e-notification-form-title {
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
}

.e-notification .e-disabled {
	opacity: 0.5;
	pointer-events: none;
	font-weight: 700;
	@extend %font14;
}

.e-notification .tab_e_tab__3yoyr {
	color: $black;
	border: none;
	padding: 6px 0px;
	border-radius: 0px;
	font-weight: 600;
	@extend %font14;
	line-height: 17px;
	border-bottom: 3px solid transparent;

	&:hover {
		background-color: transparent;
	}
}

.e-notification-form-element {
	&::placeholder {
		color: $dark_grey;
		opacity: 0.27;
	}
}

.e-notification-form-btn {
	@extend %font14;
}

.e-offer-body {
	height: 120px;
}

.e-sip-image {
	margin: 20px 0px;
	min-width: 42px;
	min-height: 42px;
	border-radius: 100%;
	object-fit: fill;
}

.e-notification-history {
	background-color: $accordion_bg;
	border-radius: 8px;

	.e-notification-history-time {
		color: $light_grey;
		line-height: 15px;
		@extend %font12;
		font-weight: 400;
	}

	.e-notification-history-content {
		color: $light_grey;
		line-height: 20px;
		@extend %font14;
		font-weight: 400;
	}

	.e-title {
		color: $dark_grey;
		font-weight: 600;
		line-height: 20px;
		@extend %font14;
	}
}

.e-notification-sec {
	background: #edf3ff;
	border-radius: 8px;
	// cursor: pointer;

	p {
		@extend %font14;
		line-height: 20px;
		color: $light_grey;
		font-weight: 400;
	}
}

.e-notification-sub-sec {
	background-color: $white;
	cursor: pointer;

	h4 {
		@extend %font14;
		line-height: 20px;
		color: $dark_grey !important;
		font-weight: 400 !important;
	}

	p {
		@extend %font14;
		line-height: 20px;
		color: $light_grey;
		font-weight: 400;
	}
}

/*----- Manage Content ------*/
.e-add-faq-btn {
	@extend %font14;
	width: 90%;
	padding: 14px;
}

/* wysiwig editor */
.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,
.ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
	padding-bottom: 300px;

	border-radius: 0px 0px 80px 8px;
	background-color: $white;
	margin-top: 20px;

	// background-color: $white;
	p {
		color: $black !important;
		font-weight: 500 !important;
		font-size: 14px !important;
		line-height: 25px;
		margin-bottom: 0;
	}

	p:nth-of-type(1) {
		margin-bottom: 20px;
	}

	h6 {
		font-weight: 600 !important;
		font-size: 14px !important;
	}
}

.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
	border: none !important;
	box-shadow: none !important;
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
	border: none;
	padding: 12px 12px 12px 0;
	background-color: $white !important;
	border-bottom: 1.6px solid #bdbdbd !important;
}

.ck.ck-dropdown {
	&[aria-labelledby="ck-editor__aria-label_e74d295f4749ee2c9e4e0304f597225aa"] {
		display: none !important;
	}
}

.ck .ck-button {
	&[aria-disabled="true"] {
		display: none !important;
	}
}

.ck-toolbar__items {
	.ck-dropdown:nth-of-type(1) {
		display: none !important;
	}

	.ck-dropdown:nth-of-type(3) {
		display: none !important;
	}

	.ck-dropdown:nth-of-type(2) {
		display: none !important;
	}
}

.ck-sticky-panel__content {
	z-index: 2 !important;
}

.ck-toolbar {
	&[aria-label="Dropdown toolbar"] {
		border-radius: 0px !important;
	}
}

.ck.ck-toolbar .ck.ck-toolbar__separator {
	display: none !important;
}

.ck-file-dialog-button {
	// display: none!important;
}

.ck .ck-button[aria-labelledby="ck-editor__aria-label_eca6d855d9e456b2b02e398f738ab9740"] {
	// display: none!important;
}

.ck .ck-button[aria-labelledby="ck-editor__aria-label_e26a6ab70fcce2fc537813a2e58106fc7"] {
	// display: none!important;
}

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
	border-color: none !important;
}

.ck.ck-editor__main>.ck-editor__editable {
	padding: 0 100px 0 28px !important;
	border-bottom: 1.6px solid #bdbdbd !important;
	height: 680px;
	overflow-y: scroll;

	&::-webkit-scrollbar {
		// border-radius: 10px;
		 height: 0.25rem !important;
		width: 8px;
	}

	&::-webkit-scrollbar-thumb {
		background: #dcdbdb;
		 height: 0.25rem !important;
		// border-radius: 10px;
	}

	&::-webkit-scrollbar-track {
		// height: 10px !important;
	}
}

.ck.ck-editor__editable_inline {
	border: none !important;
}

.ck .ck-button[aria-labelledby="ck-editor__aria-label_edd9fb7b17c77d13ecab5376a0a4cda51"] {
	display: none !important;
}

.ck.ck-toolbar>.ck-toolbar__items> :not(.ck-toolbar__line-break) {
	margin-right: 30px !important;
}

.e-manage-content {
	.e-updated-time {
		font-weight: 500;
		color: #9ea3aa;
		font-size: 14px;
		line-height: 24px;
	}

	h4 {
		font-weight: 400;
		@extend %font48;
		line-height: 120%;
		letter-spacing: -2.50105px;
		color: #11142f;
	}

	h6 {
		@extend %font18;
	}
}

/*--------------------------------*/
.e-accordion-editor {
	.ck.ck-editor {
		border: 1.5px solid #7d7688 !important;
		border-radius: 8px;
		padding: 0px 10px !important;
	}

	.ck.ck-editor__main>.ck-editor__editable {
		padding: 0 10px !important;
		border-bottom: none !important;
		height: 200px;
	}

	.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
	.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
		border: none;
		background-color: $white !important;
		border-bottom: 1.6px solid #bdbdbd !important;
	}
}

.e-tab-position {
	margin-top: -6% !important;
	position: absolute;

	h3 {
		color: $white !important;
		@extend %font24;
		font-weight: 600;
	}

	.e-active-tab {
		color: $white !important;
		@extend %font24;
		font-weight: 600;

		&::before,
		&::after {
			background-color: transparent !important;
		}
	}

	.e-tab {
		color: $tab_color;
		@extend %font24;
		font-weight: 600;

		&::before {
			background-color: $white !important;
			height: 1px !important;
		}
	}
}

.e-faq-card {
	min-height: 600px;

	h6 {
		color: $black !important;
		font-weight: 700;
	}
}

.e-cancel {
	width: 98px;
	height: 40px;
	padding: 0 !important;
	margin-right: 14px;
}

.e-modal-submit {
	font-weight: 600 !important;
	padding: 0 !important;
	@extend %font14;
	width: 155px;
	height: 40px;
}

.e-filter-btn {
	width: 36px;
	height: 36px;
}

.e-breadcrumb-link {
	font-weight: 300;
	@extend %font14;
	line-height: 17px;
	color: #bebebe;
	position: relative;
	top: -100%;

	a {
		text-decoration: none;
		color: $white;
		padding-right: 4px;

		&:hover {
			text-decoration: underline;
		}
	}
}

.e-nodata {
	width: 256px;
	height: 202px;
	margin: auto;
}

/* ------ banner --------*/
.e-border {
	position: relative;

	&::before {
		content: "";
		position: absolute;
		height: 0.2px;
		width: 100%;
		bottom: 1px;
		background-color: #dbdbdb;
	}
}

.e-uploader {
	border: 2px dashed #dbdbdb;
	border-radius: 8px;
	padding: 36px 0px;
	cursor: pointer;

	p {
		color: $light_grey;
		font-weight: 400;
		font-size: 14px;

		span {
			color: #bebebe;
			font-weight: 400;
			font-size: 12px;
			line-height: 130%;
		}
	}
}

.e-preview {
	height: 156px;
	width: 100%;

	// background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
	.e-choose-btn {
		background: $white;
		border-radius: 38px;
		color: $grey;
		font-weight: 400;
		line-height: 130%;
		@extend %font14;
		position: absolute;
		padding: 4px 12px 4px 6px;
		left: 32%;
		top: 38%;
		cursor: pointer;
	}

	.e-drag-text {
		position: absolute;
		color: $white;
		left: 10%;
		bottom: 10%;
	}
}

.e-delete {
	border: 1px solid $red !important;
	padding: 8px 16px;
	border-radius: 8px;
	color: $red;
	font-weight: 600;
	@extend %font14;
	line-height: 24px;
}

.e-type-sec {
	position: relative;

	&::before {
		content: "";
		position: absolute;
		height: 0.2px;
		width: 100%;
		bottom: 1px;
		background-color: #dbdbdb;
	}

	.e-tab {
		@extend %font14;
		font-weight: 600;
		line-height: 120%;
		color: $tab_color;
		transition: ease 0.3s;
		padding-bottom: 5px;
		position: relative;

		&:before {
			content: "";
			position: absolute;
			width: 0;
			height: 3px;
			background-color: $primary_color;
			bottom: 0;
			transition: all 0.35s;
		}

		&:hover {
			&:before {
				width: 100%;
			}

			cursor: pointer;
			opacity: 1;
		}
	}

	.e-active-tab {
		@extend .e-tab;
		transition: ease 0.3s;
		opacity: 1;
		color: $light_grey;
		font-weight: 600;

		&:after {
			content: "";
			position: absolute;
			width: 100%;
			height: 2px;
			background-color: $primary_color;
			bottom: 0px;
			left: 0;
		}
	}
}

/*----- Chat Box -------*/
.e-chat-box {
	box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.04);
	border-radius: 8px;
	height: 884px;

	.e-placeholder {
		width: 48px;
		height: 48px;
		color: $white;
		font-weight: 600;
		@extend %font14;
		line-height: 130%;
	}

	.e-user-name {
		color: $light_grey;
		@extend %font12;
	}

	.e-status-badge-open {
		color: $light_grey;
		@extend %font10;
		border: 1px solid $light_grey;
		box-sizing: border-box;
		border-radius: 100px;
		text-transform: capitalize;
		width: max-content;
		padding: 1px 8px;
	}

	.e-status-badge-pending {
		@extend .e-status-badge-open;
		color: $orange;
		border: 1px solid $orange;
	}

	.e-status-badge-closed {
		@extend .e-status-badge-open;
		color: $primary_color;
		border: 1px solid $primary_color;
	}

	h6 {
		@extend %h6;
		font-weight: 600;
	}

	.e-chat-wrap {
		transition: ease 0.3s;

		&:hover {
			background-color: rgba(185, 245, 163, 0.3);
			cursor: pointer;
		}
	}
}

/*-----Chat container ------*/
.e-chat-container {
	border-radius: 8px;
	height: 884px;

	.e-chat-header {
		box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.04);

		.e-user-placeholder {
			width: 48px;
			height: 48px;
			font-weight: 600;
			@extend %font14;
			line-height: 130%;
			color: $white;
		}

		h6 {
			color: $dark_grey;
			line-height: 130%;
			font-weight: 600;
			@extend %font18;
		}

		p {
			color: $light_grey;
			font-weight: normal;
			@extend %font14;
			line-height: 17px;
		}

		.e-ticket-label {
			@extend %h6;
		}

		.e-ticket {
			color: $light_grey;
			@extend %font14;
			line-height: 17px;
		}
	}

	.e-chat-placeholder {
		width: 32px;
		height: 32px;
		color: $white;
		@extend %font12;
		font-weight: 600;
		line-height: 130%;
	}

	.e-admin-icon {
		width: 32px;
		height: 32px;
		object-fit: cover;
	}

	.e-chat-content {
		color: $dark_grey;
		@extend %font14;
		line-height: 130%;
	}

	.e-date {
		color: $light_grey;
		line-height: 15px;
		@extend %font12;
	}

	.e-chat-row {
		border-bottom: 1px solid $off_white;
	}

	.e-closed-badge {
		background-color: $light_green;
		border-radius: 100px;
		color: $green;
		line-height: 15px;
		position: absolute;
		left: 37%;
		@extend %font12;

		img {
			width: 13.33px;
			height: 13.33px;
			object-fit: cover;
		}
	}

	.e-message-input {

		// margin-top: 310px;
		.e-input-icon {
			position: absolute;
			width: 30px;
			height: 30px;
			object-fit: cover;
			z-index: 2;
			top: 10px;
			left: 25px;
		}

		.e-sent-btn {
			position: absolute;
			right: 5px;
			z-index: 2;
			width: 50px;
			height: 100%;

			&:hover {
				cursor: pointer;
			}
		}

		.e-chat-input {
			background-color: $off_white;
			border: none;
			position: relative;
			padding: 14px 50px 14px 50px;

			&:placeholder {
				color: $light_grey;
			}
		}
	}

	.e-chatbox-wrper {
		height: 685px;
	}
}

.e-chat-active {
	background-color: red !important;
}

/*------Users-----*/
.e-filter-wraper {
	background: #F0F0F0;
	padding: 20px 0px 20px 20px;

	label {
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		color: #2b4240;
	}
}

.e-filter-btn.e-active {
	border: 1px solid $primary_color;
}

.e-investment-tooltip {
	.e-icon-tooltip {
		top: 40px;
	}
}

.e-profile-holdings {
	padding: 24px;

	.e-icon-tooltip {
		top: 58px;
		left: 26px;
	}

	h4 {
		font-family: $font-primary;
		font-weight: 700;
		@extend %font14;
		color: #2d2d2d;
	}

	// p {
	// 	font-family: $font-primary;
	// 	font-weight: 600;
	// 	@extend %font16;
	// 	color: #2d2d2d;
	// }

	.e-summary-title {
		font-family: $font-primary;
		font-weight: 500;
		@extend %font14;
		color: $light_grey;
	}
	.e-summary-value {
		font-family: $font-primary;
		font-weight: 600;
		@extend %font14;
		margin-bottom: 0;
	}

	

	.e-investment-wrapper {
		background: #f5f6ff;
		border-radius: 8px;
		padding: 16px 16px 0px 16px;

		h6 {
			font-weight: 600;
			font-size: 14px;
			line-height: 24px;
			color: #6d6d6d;
		}

		p {
			font-weight: 400;
			font-size: 14px;
			line-height: 24px;
		}
	}
}

.e-profile-graph {
	height: 100%;

	h4 {
		@extend %font14;
		color: $black;
		font-weight: 700;
	}

	p {
		font-family: $font-primary;
		font-weight: 500;
		@extend %font14;
		color: $light_grey;
	}
}

.e-graph-labels {
	max-height: 170px;
	overflow-y: scroll;
}

.e-graph-labels::-webkit-scrollbar {
	height: 0px !important;
	width: 0.375rem;
}

.e-graph-labels::-webkit-scrollbar-thumb {
	background: #edf3ff;
}

.e-manage-scrollbar::-webkit-scrollbar {
	width: 0.25rem;
	height: 0.25rem;
}

.e-manage-scrollbar::-webkit-scrollbar-thumb {
	background: $pale_cornflower_blue !important;
	padding: 0.375rem;
	width: 0.25rem;
	height: 0.25rem;
	border-radius: 16px;
}

.e-order-detail {
	max-height: 420px;
	overflow-y: scroll;
	overflow-x: hidden;

	.e-order-row {
		background: rgba(13, 13, 13, 0.02);
	}
}

.e-order-detail::-webkit-scrollbar {
	height: 0px !important;
	width: 0.375rem;
}

.e-order-detail-modal {
	.modal-content {
		padding: 0px !important;
	}
}

.e-order-detail::-webkit-scrollbar-thumb {
	background: #c4dcf3;
}

.e-icon-tooltip {
	right: 0 !important;
	max-width: 300px;
	top: 84px;
	z-index: 3;
}

.e-holding-table {
	.e-deny {
		padding: 12px 24px !important;
	}

	h4,
	.e-support-date {
		@extend %font14;
		color: $black;
		font-weight: 700;
	}

	.e-profile-active-btn {
		background: $primary_color;
		border-radius: 4px;
		color: $notification_boder;
		@extend %font14;
		padding: 4px 8px;
		width: 100%;
		margin-left: 10px;
		cursor: pointer;
	}

	.e-profile-btn {
		background: $notification_boder;
		border-radius: 4px;
		color: $black;
		@extend %font14;
		padding: 4px 8px;
		width: 100%;
		margin-left: 10px;
		cursor: pointer;
	}

	.e-holding-table-heading {
		box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
	}

	.e-holding-table-data {
		background-color: $download_bg;
		padding: 30px;

		.e-table-data {
			font-family: $font-primary;
			font-weight: 500;
			@extend %font14;
			color: $dark_grey;
			line-height: 24px;
		}

		.e-table-data-percent {
			font-family: $font-primary;
			font-weight: 500;
			@extend %font14;
			color: $dark_grey;
			position: relative;
			top: -2px;
			left: 0px;
		}

		.e-current-data-desc {
			font-family: $font-primary;
			font-style: normal;
			font-weight: 500;
			@extend %font14;
			color: #0fd99c;
		}
	}
}

.e-user-request {
	.e-user-request-sec {
		background-color: $download_bg;
		padding: 30px;
	}

	h4 {
		font-family: $font-primary;
		font-weight: 700;
		@extend %font14;
		color: $dark_grey;
	}

	p {
		color: $dark_grey !important;
		@extend %font14;

		.e-bold {
			font-weight: 600;
		}
	}
}

.e-current-title {
	font-family: $font-primary;
	font-weight: 500;
	@extend %font14;
	color: $light_grey;
}

.e-current-value {
	font-family: $font-primary;
	font-weight: 600;
	@extend %font18;
	color: $primary_color !important;
}

/*-- Analytics --*/
.e-analytics-card {
	.tab_e_active_tab__2nkVn {
		background-color: transparent !important;
		padding: 0px;
		border: none !important;
		color: $white !important;
		opacity: 1 !important;

		span {
			// padding: 12px 12px 4px 12px;
			color: $white !important;

			&::after {
				content: "";
				display: block;
				width: 100%;
				position: relative;
				bottom: 0px;
				top: 9px;
				height: 2px;
				background: $primary_color;
				transition: width 0.3s;
				z-index: 1;
			}
		}
	}

	.tab_e_tab__-VrS6 {
		padding: 0px;
		background-color: transparent !important;
		border: none !important;
		color: $white !important;

		span {
			color: $white !important;

			// padding: 12px 12px 0px 12px;
			&:hover {
				opacity: 1;
			}

			&:before {
				content: "";
				position: absolute;
				width: 0px;
				height: 3px;
				bottom: -9px;
				left: 0;
				visibility: visible;
				// color: $primary_color;
				background-color: $primary_color;
				transition: ease-in-out 0.5s;
			}

			&:hover {
				&:before {
					width: 100%;
					height: 3px;
				}
			}
		}
	}
}

.e-drop {
	color: #de2020;
}

.e-funds-list {
	max-height: 240px;
	overflow-y: scroll;

	&::-webkit-scrollbar {
		border-radius: 10px;
		height: 0px;
		width: 0.375rem;
	}

	&::-webkit-scrollbar-thumb {
		background: #c4dcf3 !important;
		border-radius: 10px;
	}

	&::-webkit-scrollbar-track {
		border-radius: 10px;
	}
}

.e-analytics {
	.css-13cymwt-control {
		min-height: 20px !important;
		border-radius: 4px !important;
	}

	.css-t3ipsp-control {
		min-height: 20px !important;
		border-radius: 4px !important;
	}
}

/*---Quiz---*/
.e-quiz-card {
	.e-quiz-scroll {
		overflow-x: scroll;
	}

	.e-quiz-scroll::-webkit-scrollbar {
		display: none;
	}

	.tab_e_tab__3yoyr,
	.tab_e_tab__2sfKQ {
		background-color: $accordion_bg !important;
		border-radius: 8px !important;
		color: $dark_grey;
		padding: 12px 15px !important;
		min-width: 218px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		text-align: center;
	}

	.tab_e_active_tab__3jjiq {
		border-radius: 8px !important;
		padding: 12px 15px !important;
		min-width: 218px;
		text-align: center;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.e-quiz-tab {
	width: 225px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.e-quiz-icon {
	position: absolute;
	top: 32%;
	right: 0px;
	background-image: url(../Assets/Images/DataFormating/add.svg);
	background-repeat: no-repeat;
	padding: 13px;
	transition: 0.5s ease-in-out;
	cursor: pointer;
}

.e-add-sec {
	border: 1px solid $accordion_body_bg;
	border-radius: 8px;
	width: 100%;
	padding: 2px 10px 7px;
	margin-bottom: 20px;
	cursor: pointer;

	h6 {
		color: $primary_color !important;
		@extend %font14;
		line-height: 24px;
		font-weight: 700 !important;
		padding: 12px 10px 0px;
		width: 100%;

		span {
			cursor: pointer;
		}

		&:hover {
			translate: scale(1.5);
		}
	}
}

.e-add-quiz {
	font-weight: 700;
	@extend %font14;
	line-height: 24px;
	color: $primary_color;
	padding: 12px 30px;
	border: 1px solid $accordion_bg;
	border-radius: 8px;
	background-color: $accordion_bg;
	cursor: pointer;

	&:hover {
		transform: scale(1.2);
	}
}

/* manage funds */
.e-allcation-accordion {
	.fundTab_e_active_tab__2B65q {
		color: $primary_color !important;
	}
}

.e-info-section {
	background: #e8eaf4;
	border-radius: 8px;
	padding: 16px 0;

	span {
		font-weight: 400;
		font-size: 12px;
		line-height: 24px;
		color: $black;
	}
}

/* manage returns */
.e-manage-returns {
	h6 {
		font-weight: 600;
	}
}

/*------ edit score request css---*/
.e-request-card {
	.e-reject-link {
		color: $badge_fail;
	}

	.e-request-status {
		font-weight: 600;
		line-height: 17px;
		@extend %font14;
		color: $primary_color;
		text-align: center;
	}

	.e-request-rejected {
		@extend .e-request-status;
		color: red;
	}

	.e-request-status-date {
		line-height: 15px;
		@extend %font12;
		color: $light_grey;
		font-weight: 400;
		text-align: center;
	}
}

/*-- report css--*/
.e-report {
	min-height: 140vh;

	p {
		font-weight: 400;
		@extend %font14;
		line-height: 20px;
		color: $dark_grey;
		padding-right: 4px;
	}

	h6 {
		font-weight: 600;
		@extend %font14;
		line-height: 20px;
		color: $dark_grey;
	}

	.e-report-save-btn {
		padding: 8px 16px;

		span {
			line-height: 24px;
		}
	}

	.e-send-report {
		padding: 8px 16px;

		span {
			line-height: 24px;
		}
	}
}

/* manage global class css */
.e-global-class-card {
	background-color: $accordion_bg !important;
	border-radius: 8px !important;
	color: #010101;
	padding: 12px 24px !important;
	min-width: 218px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	// background-image: url("../Assets/Images/cta/delete-topic.svg");
	// background-repeat: no-repeat;
	// background-position: 95%;
}

/* -- modal select and button --*/
.modal .css-1s2u09g-control,
.modal .css-t3ipsp-control {
	border-radius: 8px !important;
	height: 47px;

	&:hover {
		border-radius: 8px !important;
	}
}

.modal .e-disabled {
	opacity: 0.5;
	pointer-events: none;
	font-weight: 700;
	@extend %font14;
}

.e-edit-modal {
	width: 109px !important;
}

/*--- phone input css---*/
// .PhoneInput {
// 	background: $white;
// 	border: 1px solid $input_boder;
// 	background-color: transparent;
// 	outline: none;
// 	width: 100%;
// 	font-weight: 400;
// 	color: $black;
// 	padding: 10px 15px;
// 	border-radius: 8px;

// 	&::focus {
// 		border: 1px solid $primary_color;
// 		border-radius: 8px;
// 	}
// }

// .PhoneInput--focus::focus {
// 	border: 1px solid $primary_color;
// 	border-radius: 8px;
// }

// .PhoneInput--focus {
// 	border: 1px solid $primary_color;
// 	outline: none !important;

// 	&::placeholder {
// 		font-weight: 500;
// 		@extend %font14;
// 		line-height: 18px;
// 		color: $black;
// 		opacity: 0.47;
// 	}
// }

// .PhoneInput {
// 	input {
// 		border: none !important;
// 		outline: none !important;

// 		&::placeholder {
// 			font-weight: 400;
// 			@extend %font14;
// 			line-height: 18px;
// 			color: $input_placeholder;
// 			letter-spacing: 0.25px;
// 		}

// 		&:focus {
// 			border: none !important;
// 			outline: none !important;
// 		}
// 	}
// }

.ant-pagination-options-size-changer.ant-select {
	width: auto;
	display: none;
}

/*-------loader---*/
.e-btn-loader span {
	&:after {
		content: " .";
		font-family: "Epilogue";
		margin: 0;
		line-height: 100%;
		animation: dots 1s steps(1, end) infinite;
	}
}

.e-butn-loader {
	&:after {
		content: " .";
		font-family: "Epilogue";
		margin: 0;
		line-height: 100%;
		animation: dots 1s steps(1, end) infinite;
	}
}

@keyframes dots {

	0%,
	12.5% {
		opacity: 0;
	}

	25% {
		opacity: 1;
	}

	50% {
		text-shadow: 0.5em 0;
	}

	75%,
	100% {
		text-shadow:
			0.5em 0,
			1em 0;
	}
}

/*---------------Campaign detail----------*/
.e-campaign-detail-wrapper {
	background: $white;
	box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.04);
	border-radius: 16px;
	padding: 24px;

	h6 {
		font-weight: 600;
		font-size: 14px;
		line-height: 21px;
		color: $light_grey;
	}

	.e-delete-btn {
		color: $red;
	}

	h3 {
		@extend %h3;
		font-weight: 600;
		line-height: 30px;
		color: $grey;
	}

	.e-campaign-content-wrapper {
		background: #f5f6ff;
		border-radius: 16px;
		padding: 24px;
		height: 182px;

		h6 {
			font-weight: 600;
			font-size: 14px;
			line-height: 21px;
			color: #6d6d6d;
		}

		p {
			font-weight: 400;
			font-size: 14px;
			line-height: 21px;
			color: #2d2d2d;
		}

		.e-toggle-span {
			font-weight: 400;
			font-size: 14px;
			color: #2d2d2d;
		}

		ul {
			padding-left: 24px;

			li {
				font-weight: 400;
				font-size: 14px;
				line-height: 24px;
				color: #6d6d6d;
			}
		}
	}
}

.e-prefix-input {
	padding: 12px 15px 12px 24px !important;
}

.e-campaign-date {
	
}

.ant-picker {
	height: auto;
	width: 100%;
	padding-block: 9px;
	border: 0.5px solid rgba(190, 190, 190, 1) !important;
	border-radius: 8px !important;
}

.ant-picker-input > input::placeholder {
	color: $dark_grey;
	opacity: 0.27;
	font-weight: 400;
	line-height: 18px;
	font-size: 14px;
}

.e-campaign-cancel {
	height: 40px;
	text-align: center;
	border: 1px solid #bebebe;
	padding: 8px 15px 8px 12px;
	border-radius: 8px;
	font-weight: 700;
	color: #6d6d6d;
	font-weight: 600;
}

/*user detail*/

.e-graph-data-wrapper1 {
	background: #ffffff;
	border: 1px solid #dbdbdb;
	border-radius: 16px;
	padding: 12px 46px 18px;

	div {
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		color: #6d6d6d;

		.e-graph-class {
			width: 8px;
			height: 8px;
			padding: 2px;
			border-radius: 100px;
			position: relative;
			top: 4px;
			margin-right: 6px;
		}

		.e-target-class {
			background: #4f7097;
		}

		.e-performance-class {
			background: #106ee8;
		}
	}
}

.e-link {
	text-decoration-line: underline;
	cursor: pointer;
}

.apexcharts-text tspan {
	font-size: 9.5px !important;
}

.e-graph-data-wrapper2 {
	background: #ffffff;
	border: 1px solid #dbdbdb;
	border-radius: 16px;
	padding: 12px 16px;

	p {
		font-weight: 400;
		font-size: 12px;
		line-height: 135%;
		color: #6d6d6d;
	}

	&::-webkit-scrollbar {
		// border-radius: 10px;
		 height: 0.25rem !important;
		width: 0.375rem;
	}

	&::-webkit-scrollbar-thumb {
		background: #4f7097;
		padding: 20px;
		 height: 0.25rem !important;
		border-radius: 10px;
	}

	&::-webkit-scrollbar-track {
		// height: 10px !important;
	}
}

// basket
.e-basket-section {
	border: 1px solid #dbdbdb;
	border-radius: 8px;
	width: 100%;
	padding: 10px 14px;
	margin-bottom: 20px;

	.e-basket-body {
		background: #f0f0f0;
		border-radius: 8px;
		padding: 10px;
		margin: 2px;
	}

	.e-allocation {
		font-weight: 700;
		line-height: 20px;
		padding: 0px 16px;
		color: #010101;
		font-size: 14px;
	}

	.e-body-allocation {
		font-weight: 600;
		line-height: 20px;
		color: #010101;
		font-size: 14px;
	}
}

.e-update {
	color: $primary_color;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
	cursor: pointer;
}

.e-disable {
	opacity: 0.5 !important;
	pointer-events: none;
}



.e-back-btn {
	transform: rotate(180deg);
	margin-left: -12px;
}

.e-dashboard-card-sort .css-1n6sfyn-MenuList .css-tr4s17-option {
	background: #526ce0 !important;
	font-family: "Manrope";
}

.e-select-box .css-tj5bde-Svg {
	width: 16px !important;
}

.e-select-box {
	cursor: pointer;
}

.e-select-box .css-tr4s17-option {
	background: #526ce0 !important;
	font-family: "Manrope";
}

.e-toast .Toastify__toast-body {
	// color: red;
	font-family: "Manrope";
	font-size: 14px;
	font-weight: 500;
}

.e-max-height-420px {
	max-height: 420px;
}

.e-max-height-260px {
	max-height: 260px;
}

.e-padding-80-50-16 {
	padding: 80px 50px 16px !important;
}


//------ BORDER RADIUS -----//

.e-border-radius-16px {
	border-radius: 1rem;
}

//------ BACKGROUND CLASSES -----//

.e-filter-label {
	font-family: "Manrope";
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 1.2rem;
}

#data-table {

	.rdt_TableHeadRow, .rdt_TableRow {
		padding-left: 0.5rem;
	}

	.sc-kDvujY  {
		font-size: 0.75rem !important;
    	margin-top: 2px !important;
	}

}

.e-notify {
	font-family: "Manrope";
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 1.5rem;
	color: $primary_color;
}


// FONT FAMILY
.e-font-manrope {
	font-family: "Manrope";
}

.e-font-inter {
	font-family: "Inter";
}

.border-1px-accordion_body_bg {
	    border: 0.5px solid $accordion_body_bg;
}


// HEIGHT-CLASSES

.h-max-content {
    height: max-content !important;
}

.h-1 {
    height: 0.063rem;
}

.h-2 {
    height: 0.125rem;
}

.h-6 {
    height: 0.375rem;
}

.h-10 {
    height: 0.625rem;
}

.h-12 {
    height: 0.75rem;
}

.h-16 {
    height: 1rem;
}

.h-24 {
    height: 1.5rem;
}

.h-28 {
    height: 1.75rem;
}

.h-16 {
    height: 1rem;
}

.h-20 {
    height: 1.25rem;
}

.h-24 {
    height: 1.5rem;
}


.h-22 {
    height: 1.375rem;
}

.h-28 {
    height: 1.75rem;
}

.h-30 {
    height: 1.875rem;
}

.h-34 {
    height: 2.125rem;
}

.h-40 {
    height: 2.5rem;
}

.h-48 {
    height: 3rem;
}

.h-52 {
	height: 3.25rem;
}

.h-66px {
    height: 4.125rem;
}

.h-100px {
    height: 100px !important;
}

.h-400px {
    height: 400px !important;
}

.h-200px {
    height: 200px !important;
}

.h-240px {
    height: 240px !important;
}

.h-90vh {
	height: 90vh;
}

.e-font-weight-400 {
	font-weight: 400;
}
.e-font-weight-500 {
	font-weight: 500 !important;
}
.e-font-weight-600 {
	font-weight: 600 !important;
}
.e-font-weight-700 {
	font-weight: 700 !important;
}
.e-font-weight-800 {
	font-weight: 800 !important;
}

// FONT SIZE 
.e-font-10 {
    font-size: 0.625rem;
}

.e-font-12 {
    font-size: 0.75rem;
}

.e-font-14 {
    font-size: 0.875rem !important;
}

.e-font-16 {
    font-size: 1rem !important;
}

.e-font-18 {
    font-size: 1.125rem !important;
}

.e-font-24 {
    font-size: 1.5rem ;
}

.e-font-32 {
    font-size: 2rem ;
}

// LINE HEIGHT

.line-height-0 {
    line-height: 0;
}


.line-height-8px {
    line-height: 0.5rem;
}

.line-height-10px {
    line-height: 0.625rem;
}

.line-height-12px {
    line-height: 0.75rem;
}

.line-height-14px {
    line-height: 0.875rem !important;
}

.line-height-15px {
    line-height: 0.9375rem;
}

.line-height-16px {
    line-height: 1rem;
}

.line-height-17px {
    line-height: 1.0625rem;
}

.line-height-18px {
    line-height: 1.125rem;
}

.line-height-20px {
    line-height: 1.25rem;
}

.line-height-22px {
    line-height: 1.375rem;
}

//COLORS

.e-text-primary {
    color: $primary_color;
}

.e-text-white {
    color: $white;
}

.e-text-black {
    color: $black;
}

.e-text-tab-color {
    color: $tab_color;
}

.e-text-chinese-black {
	color: $chinese_black;
}

.e-text-light-grey {
	color: $light_grey;
}

.e-text-dark-grey {
	color: $dark_grey;
}

.e-text-grey {
	color: $grey;
}

.e-text-red {
	color: $red;
}


.e-text-egyptian-blue {
    color: $egyptian_blue;
}

.e-text-ochre {
    color: $ochre;
}

.e-text-slate-gray {
    color: $slate_gray;
}

.e-text-raisin-black {
    color: $raisin_black;
}

.e-text-onyx {
    color: $onyx;
}

.e-text-mustard-yellow {
    color: $mustard_yellow;
}


.e-text-go-green {
    color: $go_green;
}

.e-text-dark-liver {
    color: $dark_liver;
}

.e-text-dark-silver {
    color: $dark_silver;
}

.e-text-ochre {
    color: $ochre;
}


// Cursor



.cursor-pointer {
    cursor: pointer;
}

.cursor-default {
    cursor:default;
}

.cursor-text {
    cursor:text;
}

.cursor-none {
    cursor: none !important;
}

.e-cursor-none {
    cursor: not-allowed !important;
}


// BORDER RADIUS CLASSES
.e-border-radius-4 {
    border-radius: 0.25rem !important;
}
.e-border-radius-6 {
    border-radius: 0.375rem !important;
}
.e-border-radius-8 {
    border-radius: 0.5rem !important;
}

.e-border-radius-10 {
    border-radius: 0.625rem !important;
}

.e-border-radius-12 {
    border-radius: 0.75rem !important;
}

.e-border-radius-16 {
    border-radius: 1rem !important;
}

.e-border-radius-24 {
    border-radius: 1.5rem !important;
}

.e-border-radius-top-left-16 {
	border-radius: 0 1rem 0 0;
}

.e-border-radius-50per {
    border-radius: 50%;
}

.e-border-chinese-white {
	border-color: $chinese_white;
}


// BACKGROUND COLOR

.e-bg-white {
	background: $white;
}

.e-bg-accordion-bg {
	background: $accordion_bg;
}

.e-bg-anti-flash-white {
    background: $anti_flash_white;
}


.e-bg-primary {
	background: $primary_color;
}

.e-bg-soap {
	background: $soap;
}

.e-bg-cornflower-blue {
	background: $cornflower_blue;
}

.e-bg-cyan-azure {
	background: $cyan_azure;
}

.e-bg-ghost-white {
	background: $ghost_white;
}

.e-bg-header-bg {
	background: $header_bg;
}

.e-bg-lavender {
	background: $lavender;
}

.e-bg-cultured {
    background: $cultured;
}

.e-profile-gradient {
    background: linear-gradient(192deg, $lavender 49.89%, #EADEFF 98.8%), #FFF;
}

.e-bg-lotion {
    background: $lotion;
}

.e-bg-cornsilk {
    background: $cornsilk;
}

.e-bg-alice-blue {
    background: $alice_blue ;
}

.e-bg-smoky-black {
    background: $smoky_black ;
}

.e-bg-goal {
    background: $goal_bg ;
}

.e-bg-body-bg {
    background: $body_bg;
}

.e-bg-snow {
    background: $snow;
}

.e-bg-misty-rose {
    background: $misty_rose;
}

.e-bg-lavender-blue {
    background: $lavender_blue;
}

.e-bg-anti-flash-white {
    background: $anti_flash_white;
}

.e-bg-cosmic-latte {
    background: $cosmic_latte;
}

.e-bg-nyanza-green {
    background: $nyanza_green;
}


.e-bg-search-bg {
    background: $search_bg;
}

.e-bg-lavender-blue-dark {
	background: $lavender_blue_dark;
}





// Padding

.padding-2-top {
    padding-top: 0.125rem;
}

.padding-2px-all {
    padding: 0.125rem;
}

.padding-32px-top {
    padding-top: 2rem;
}

.padding-40px-top {
    padding-top: 2.5rem;
}

.e-padding-tb-10px {
	padding-block: 0.625rem;
}

.e-padding-tb-12px {
	padding-block: 0.75rem;
}

.e-padding-tb-14px {
	padding-block: 0.875rem;
}

.e-padding-tb-15px {
	padding-block: 0.938rem;
}

.e-padding-tb-16px {
	padding-block: 1rem;
}



.e-padding-lr-12px {
	padding-inline: 0.75rem;
}

.e-padding-lr-56px {
	padding-inline: 3.5rem;
}

.e-padding-bottom-80px {
	padding-bottom: 5rem ;
};

.e-padding-all-32px {
	padding: 2rem ;
};

.e-padding-all-12px {
	padding: 0.75rem; ;
};

// MARGIN

.margin-40px-left {
    margin-left: 2.5rem;
}

.margin-bottom-32px {
	margin-bottom:2rem;
}

.margin-top-32px {
	margin-top:2rem;
}

.margin-top-12px {
	margin-top:0.75rem;
}

.margin-top-8px {
	margin-top:0.5rem;
}

.margin-top-14px {
	margin-top:0.875rem;
}



// Line Height

.line-height-0px {
    line-height: 0;
}

.line-height-8px {
    line-height: 0.5rem;
}

.line-height-10px {
    line-height: 0.625rem;
}

.line-height-12px {
    line-height: 0.75rem;
}

.line-height-14px {
    line-height: 0.875rem !important;
}

.line-height-15px {
    line-height: 0.9375rem;
}

.line-height-16px {
    line-height: 1rem;
}

.line-height-17px {
    line-height: 1.0625rem;
}

.line-height-18px {
    line-height: 1.125rem;
}

.line-height-20px {
    line-height: 1.25rem;
}

.line-height-22px {
    line-height: 1.375rem;
}

.line-height-24px {
    line-height: 1.5rem;
}

.line-height-26px {
    line-height: 1.625rem;
}

.line-height-28px {
    line-height: 1.75rem;
}

.line-height-30px {
    line-height: 1.875rem;
}

.line-height-32px {
    line-height: 2rem;
}


/* letter spacing */

.letter-spacing-3 {
    letter-spacing: 0.019rem;
}

.letter-spacing-4 {
    letter-spacing: 0.25rem;
}

.letter-spacing-5 {
    letter-spacing: 0.031rem;
}

.letter-spacing-neg-2 {
    letter-spacing: -0.013rem;
}

.d-grid-sip-date {
    display: grid;
    grid-template-columns: repeat(7, auto);
}

.e-sip-date:hover {
    background-color: #8a9ae2;
    color: #fff;
}

// GAP 

.gap-26px {
	gap:1.625rem;
}

.gap-12px {
	gap:0.75rem;
}

.gap-20px {
	gap:1.25rem;
}


.e-gap-0-12px {
    gap: 0 0.75rem;
}

// CUSTOM STYLES


.e-ellipse {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

// Suggest modal shadow
.e-modal-box-shadow {
    box-shadow: 0 0.5rem 0.625 -0.375rem rgba(0, 0, 0, 0.10), 0 1.25rem 1.563rem -0.313rem rgba(0, 0, 0, 0.10);
}


#footer-link {
	&:hover, &:active, &:focus {
		color: $dark_grey;
	}
}

.text-wrap {
	word-wrap: break-word;
	word-break: break-all;
}

.slider-track-0 {
    background-color: $egyptian_blue !important;
}

// BORDER

.e-border-red-1px {
    border: 0.063rem solid $red;
}

.e-border-1px-primary-blue {
	border: 0.063rem solid $primary_color;
}

.e-border-1px-tab-color {
	border: 0.063rem solid $tab_color;
}

.e-border-1px-sonic-silver {
    border: 0.063rem solid $sonic_silver;
}

.e-border-1px-egyptian-blue {
	border: 0.063rem solid $egyptian_blue;
}

.e-border-bottom-1px-light-gray {
    border-bottom: 0.063rem solid $light_gray;
}

.e-border-bottom-1px-platinum-gray {
    border-bottom: 0.063rem solid $platinum_gray;
}

.e-border-otp-active {
    border: 0.063rem solid $egyptian_blue;
}

.e-border-otp {
    border: 0.063rem solid $light_gray;
}

.e-border-1px-chinese-black {
    border: 0.063rem solid $chinese_black;
}

.e-border-1px-bright-gray {
    border: 0.063rem solid $bright_gray;
}

.outline-none {
	outline: none;
}

.e-error-message {
    color: $red;
}

//overlay
.overlay-background {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: 1030;
    overflow: hidden !important;
}

// WIDTH

.w-24px {
    width: 1.5rem;
    /* 24px / 16px */
}

.w-48px {
	width: 3rem;
}

.width-576px {
	width: 36rem;
}

.width-56px {
	width: 3.5rem;
};

.width-100px {
	min-width: 100px;
};

.min-width-460px {
	max-width: 460px !important;
}

.w-40per {
	width: 40%;
}

.w-50per {
	width: 50%;
}

.w-60per {
	width: 60%;
}

// Position

.right-16px {
    right: 1rem;
}

.top-10px {
	top: 0.0.625rem;
}

.top-90 {
	top: 90%;
}

.z-index {
	z-index: 999;
}

.z-index-1100 {
	z-index: 1100;
}

.top-12px {
	top:0.75rem !important;
}

.top-46px {
	top:2.9rem !important;
}

.top-neg-10px {
    top: -0.625rem !important;
    /* 10px / 16px */
}

.bottom-30px {
    bottom: 1.875rem;
    /* 10px / 16px */
}

.right-16px {
    right: 1rem;
    /* 16px / 16px */
}

.text-decoration-hover-underline:hover {
    text-decoration: underline !important;
}

//manage modal scroll 
.e-manage-modal .modal-body::-webkit-scrollbar-thumb {
    background: $pale_cornflower_blue !important;
    border-radius: 2rem !important;
}

.e-manage-modal .modal-body::-webkit-scrollbar {
    width: 0.275rem !important;
    display: block !important;
}

#fund-allocation-table {

	// .rdt_Table {
	// 	padding-left: 0.5rem;
	// }
	[data-column-id = "1"]  {
		padding-left: 0.5rem;
	}
	.rdt_TableHead {
		background-color: #e1e4fb !important;
	}
	.rdt_TableBody {
		padding-bottom: 0;
	}

	

	.rdt_TableRow:nth-of-type(even) {
		background-color: #f4f5ff !important;
	}

	.rdt_TableRow:nth-of-type(odd) {
		
		// background-color: #0D0D0D05 !important;
		background-color: #e7e8f8b0 !important;
	}
}


// React Slider

.slider-thumb {
  cursor: pointer;
  outline: none;
  width: 1.5rem;
  height: 1.5rem;
  background: $primary_color;
  border-radius: 100%;
}

.slider-track {
  background: $gainsboro;
  height: 4px;
}

.slider.slider-track-0 {
  background: $primary_color;
}






/////////// React- calender Style ////////////
.react-calendar {
	    width: auto !important;
        font-family: $font-primary;
        background: $white;
        border-radius: 0.75rem !important;
        padding: 1rem;
        border: none;
        box-shadow: 0 0.5rem 0.625rem -0.375rem rgba(0, 0, 0, 0.1), 0 1.25rem 1.563rem -0.313rem rgba(0, 0, 0, 0.1);

}

.react-calendar__tile--hasActive {
    background: $primary_color !important;
    color: $white;
}

.react-calendar__month-view {
    background: linear-gradient(192deg, $lavender 49.89%, #EADEFF 98.8%), #FFF;
    border-radius: 0.75rem;
    padding: 0.5rem;
}


.react-calendar__tile:enabled:hover {
    background-color: $primary_color;
    color: $white;
}

.react-calendar__tile--active {
    background-color: $egyptian_blue !important;
    color: $white !important;
}

.react-calendar__navigation {
    .react-calendar__navigation__label__labelText {
        font-size: 1rem;
        font-weight: 600;
    }
}

.react-calendar__month-view__weekdays {
    font-size: 0.875rem;
    font-weight: 600;

    abbr {
        text-decoration: none;

    }
}

.react-calendar__month-view__days {

    abbr {
        font-size: 1rem;
        font-weight: 400;
    }
}

.react-calendar__tile--now {
    background: transparent;
}

.e_input_wrapper {
    border-radius: 0.5rem;
    font-size: 0.875rem;
    cursor: pointer;
}

/////////// React- calender Style end ////////////


.cursor-pointer {
	cursor: pointer;
}

.border-1px-bottom-hover:hover {
	border-bottom: 1px solid;
}

.e-transition-all {
	transition: all 0.3s;
}

#support-ticket-modal .modal-dialog {
	width: 500px;
	margin: auto;
}

// WIDTH 

.min-width-170px {
	min-width: 170px;
}


// POSITION
.left-25-per {
	left: 25%;
}

.text-link {
	&:hover, &:active {
		color:$primary_color;
	}
}

.e-disable {
    opacity: 0.5 !important;
    pointer-events: none;
}

.e-caret-color-transparent {
    caret-color: transparent;
}

// React select
.react-select__control--menu-is-open .react-select__dropdown-indicator svg {
   transform: rotate(180deg);
}

.react-select__menu-list::-webkit-scrollbar {
	display: none;
}

 .e_search_close {
    background-image: url("../Assets/Images/Cta/close-black.png");
    background-repeat: no-repeat;
    width: 10px;
    height: 10px;
    background-size: contain;
    border: none !important;
    outline: none !important;
    background-color: transparent;
	background-color: $search_bg;
	transform: translateY(-50%);
    top: 50%;
	right: 0%;
    opacity: 0.5;
    position: absolute;
    &:hover {
      opacity: 1;
    }
  }

  #order-fund {
	&:hover {
		background-color:$white;
	}
  }


//   ANT date picker style
.ant-picker {
	display: flex;
	justify-content: space-between;
	gap: 1rem;
}
.ant-picker-input {
	width: 79px;
}

.btn-close {
	background-size: 0.875rem;
    padding: 0rem;
}

.rotate-90deg {
	transform: rotate(90deg);
} 

.rotate-min-180deg {
	transform: rotate(-180deg);
} 

// React Popup style
.e-popup-content {
	border: none !important;
	box-shadow: none !important;
	border-radius: 0.5rem !important;
}

.e-popup-arrow {
	margin-top: 2px !important;
	margin-left: 2px !important;
	box-shadow: none !important;
}

// phone input plugin
.PhoneInput {
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
    border: 0.063rem solid $tab_color;
    font-size: 1rem;
  	font-family: $font-primary;
    font-weight: 400;
    line-height: 1.375rem;
	padding: 0;

 .custom-input {
        background: transparent;
        font-size: 0.875rem;
       font-family: $font-primary;
        font-weight: 400;
        line-height: 1.375rem;
        border: none;
        outline: none;
        padding-block: 0.8rem;
        padding-right: 0.25rem;
        accent-color: red;
        &::placeholder  {
            font-size: 0.875rem;
        }
        
    }

}

.PhoneInput[data-focus="true"] {
    border: 0.063rem solid $primary_color;
}

#phone-dropdown-arrow {
	path {
		fill :$raisin_black
	}
}

