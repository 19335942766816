@charset "utf-8";
/* ==============================
   Small desktop
   ============================== */
@media only screen and (max-width: 1600px) {

}
@media screen and (max-width: 1400px) {
   .e-dashboard-card-text{
      font-size: 28px!important;
   }
  
}

@media only screen and (max-width: 1366px) {

}

@media only screen and (max-width: 1200px) {


}


@media only screen and (max-width: 1199px) {
   /*Header*/
  .e-header-desktop{
    display: none;
  }
  .e-header-mobile{
    display: block;
  }
  .e-dashboard-card-text{
      font-size: 24px!important;
   }

}

/* ==============================
   Tablet landskape
   ============================== */
@media only screen and (max-width: 1024px) {

}

@media only screen and (max-width: 992px) {


}

/* ==============================
   Tablet Portrait
   ============================== */
@media only screen and (max-width: 768px) {


}

/* ==============================
   Phone
   ============================== */
@media only screen and (max-width: 575px) {
   
   .width-auto-res {
      width: auto;
   }

   .width-20px-res {
        width:1.25rem;
   }


   #support-ticket-modal .modal-dialog {
      width: 100% !important;
      margin: auto;
   }
}


@media only screen and (max-width: 380px) {
    .sip-date-picker {
        .d-grid-sip-date {
            gap:2px !important
        }
        .e-font-res-date {
            font-size: 0.75rem !important;
            margin-block: 0.25rem;
        }
    }
}


/* ==============================
   Small Phone
   ============================== */
@media only screen and (max-width: 319px) {


}