/*
 *   File : download.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Download Button state
 *   Integrations :
 *   Version : v1.0
 *   Created : 28-11-2023
 */

@import "./../config.scss";

.e_download_btn {
  opacity: 0.4;
  min-width: 150px;
  pointer-events: none;
  padding-top: 6px;
  border: none;
  background-color: transparent;
  span {
    @extend %font14;
    font-weight: 400 !important;
    color: $primary_color;
  }
  img {
    opacity: 0.4;
    pointer-events: none;
    width: 24px;
    height: auto;
  }
}
.e_download_btn_active {
  min-width: 150px;
  background: $download_bg;
  border: 1px solid $notification_boder;
  padding: 4px 10px 4px 10px !important;
  border-radius: 4px;
  span {
    @extend %font14;
    font-weight: 500 !important;
    color: $primary_color;
  }
  img {
    opacity: 1;
    width: 24px;
    height: auto;
  }
  &:hover {
    background-color: $white;
    border: 1px solid $download_bg;
    transition: 0.5s ease-in-out;
  }
}
