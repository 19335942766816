@import "./../config.scss";
.e_switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .e_slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #959595;
    box-shadow: inset 0px 6px 8px 3px rgba(0, 0, 0, 0.1);
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
    &:before {
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      left: 2.6px;
      top: 2px;
      background: $white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
    }
  }
}
input:checked + .e_slider {
  background-color: $primary_color;
}

input:focus + .e_slider {
  box-shadow: 0 0 1px $primary_color;
}

input:checked + .e_slider:before {
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}
