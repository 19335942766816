/*
 *   File : addFaq.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : FAQ Accordion style
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";
.e_risk {
  h6 {
    @extend %h6;
    @extend %font14;
    color: $dark_grey !important;
    font-weight: 700 !important;
  }
  .e_modal_dialog {
    max-width: 420px !important;
  }
  //  .secondary_e_s_btn__39sgM {
  //    text-align: center!important;
  //  }
  .e_cancel {
    border: solid 1px $light_grey !important;
    color: $light_grey;
  }
  .primary_e_p_btn__3-79w {
    text-align: center !important;
    padding: 8px 16px;
  }
}
.e_disabled {
  opacity: 0.7;
  pointer-events: none;
  font-weight: 600;
}
