/*
 *   File : loader.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : loader
 *   Integrations :
 *   Version : v1.0
 *   Created : 28-11-2023
 */

@import "./../config.scss";

.e_loader {
  width: 100%;
  height: 100vh !important;
  background-color: transparent;
  img {
    width: 34px;
    height: 34px;
    position: absolute;
    top: 31%;
    left: 50%;
    transform: translateX(-50%);
  }
}
